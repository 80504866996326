const root = `reports`;

export default [
  {
    path: `/${root}`,
    name: `${root}`,
    component: () => import(`@/views/${root}/index.vue`),
    meta: {
      layout: `Admin`,
      requiresAuth: true,
      isAdmin: true,
    },
    children: [
      {
        path: `/${root}`,
        redirect: () => {
          return `/${root}/sales-by-day`;
        },
      },
      {
        path: `/${root}/sales-by-day`,
        name: `${root}-sales-by-day`,
        component: () => import(`@/views/${root}/sales-by-day`),
      },
    ],
  },
];
