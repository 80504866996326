export default [
  {
    path: "/account",
    name: "account-notifications",
    component: () => import("@/views/account"),
    meta: {},
    children: [
      {
        path: "/account/notifications",
        name: "account-notifications",
        component: () => import("@/views/account/Notifications"),
      },
      {
        path: "/account/profile",
        name: "account-profile",
        component: () => import("@/views/account/Profile"),
      },
      {
        path: "/account/security",
        name: "account-security",
        component: () => import("@/views/account/Security"),
      },
      {
        path: "/account/help",
        name: "account-help",
        component: () => import("@/views/account/Help"),
      },
    ],
  },
];
