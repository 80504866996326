const state = () => ({
  token: null,
  refreshToken: null,
  user: null,
  organization: null,
});

// getters
const getters = {
  getToken: (state) => {
    return state.token;
  },

  getRefreshToken: (state) => {
    return state.refreshToken;
  },

  getUser: (state) => {
    return state.user;
  },

  getOrganisation: (state) => {
    return state.organization;
  },
};

// actions
const actions = {
  setToken: ({ commit }, token) => {
    commit("SET_TOKEN", token);
  },

  setRefreshToken: ({ commit }, refreshToken) => {
    commit("SET_REFRESH_TOKEN", refreshToken);
  },

  removeToken: ({ commit }) => {
    commit("UNSET_TOKEN");
  },

  removeRefreshToken: ({ commit }) => {
    commit("UNSET_REFRESH_TOKEN");
  },

  setUser: ({ commit }, user) => {
    commit("SET_USER", user);
  },

  removeUser: ({ commit }) => {
    commit("UNSET_USER");
  },

  setOrganization: ({ commit }, organization) => {
    commit("SET_ORGANIZATION", organization);
  },

  removeOrganization: ({ commit }) => {
    commit("UNSET_ORGANIZATION");
  },
};

// mutations
const mutations = {
  SET_TOKEN(state, payload) {
    state.token = payload;
  },

  SET_REFRESH_TOKEN(state, payload) {
    state.refreshToken = payload;
  },

  UNSET_TOKEN(state) {
    state.token = null;
  },

  UNSET_REFRESH_TOKEN(state) {
    state.refreshToken = null;
  },

  SET_USER(state, payload) {
    state.user = payload;
  },
  UNSET_USER(state) {
    state.user = null;
  },

  SET_ORGANIZATION(state, payload) {
    state.organization = payload;
  },
  UNSET_ORGANIZATION(state) {
    state.organization = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
