const state = () => ({
  currentUser: null,
});

// getters
const getters = {
  getUser: (state) => {
    return state.user;
  },
};

// actions
const actions = {
  setUser: ({ commit }, user) => {
    console.log({ user });
    commit("SET_USER", user);
  },

  removeUser: ({ commit }) => {
    commit("UNSET_USER");
  },

  replaceOrgLogo: ({ commit }, logo) => {
    commit("REPLACE_ORG_LOGO", logo);
  },

  replaceColorBrand: ({ commit }, brand) => {
    commit("SET_COLOR_BRAND", brand);
  },

  replaceColorAccent: ({ commit }, accent) => {
    commit("SET_COLOR_ACCENT", accent);
  },
};

// mutations
const mutations = {
  SET_USER(state, payload) {
    console.log({ payload });
    state.currentUser = payload;
  },
  UNSET_USER(state) {
    state.currentUser = null;
  },

  REPLACE_ORG_LOGO(state, payload) {
    state.currentUser.Organization.LogoImage = payload;
  },

  SET_COLOR_BRAND(state, payload) {
    state.currentUser.Organization.ColorBrand = payload;
  },

  SET_COLOR_ACCENT(state, payload) {
    state.currentUser.Organization.ColorAccent = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
