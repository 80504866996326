const state = () => ({
  token: null,
  refreshToken: null,
  isAdmin: false,
  isOrgManager: false,
  isAuthenticated: false,
});

// getters
const getters = {
  getToken: (state) => {
    return state.token;
  },

  getRefreshToken: (state) => {
    return state.refreshToken;
  },

  getIsAuthenticated: (state) => {
    return state.isAuthenticated;
  },

  getIsAdmin: (state) => {
    return state.isAdmin;
  },

  getIsOrgManager: (state) => {
    return state.isOrgManager;
  },
};

// actions
const actions = {
  setToken: ({ commit }, token) => {
    commit("SET_TOKEN", token);
  },

  setRefreshToken: ({ commit }, refreshToken) => {
    commit("SET_REFRESH_TOKEN", refreshToken);
  },

  removeToken: ({ commit }) => {
    commit("UNSET_TOKEN");
  },

  removeRefreshToken: ({ commit }) => {
    commit("UNSET_REFRESH_TOKEN");
  },

  setIsAuthenticated: ({ commit }, isAuthenticated) => {
    commit("SET_IS_AUTHENTICATED", isAuthenticated);
  },

  removeIsAuthenticated: ({ commit }) => {
    commit("UNSET_IS_AUTHENTICATED");
  },

  setIsAdmin: ({ commit }, isAdmin) => {
    commit("SET_IS_ADMIN", isAdmin);
  },

  removeIsAdmin: ({ commit }) => {
    commit("UNSET_IS_ADMIN");
  },

  setIsOrgManager: ({ commit }, isAdmin) => {
    commit("SET_IS_ORG_MANAGER", isAdmin);
  },

  removeIsOrgManager: ({ commit }) => {
    commit("UNSET_IS_ORG_MANAGER");
  },
};

// mutations
const mutations = {
  SET_TOKEN(state, payload) {
    state.token = payload;
  },

  SET_REFRESH_TOKEN(state, payload) {
    state.refreshToken = payload;
  },

  UNSET_TOKEN(state) {
    state.token = null;
  },

  UNSET_REFRESH_TOKEN(state) {
    state.refreshToken = null;
  },

  SET_IS_AUTHENTICATED(state) {
    state.isAuthenticated = true;
  },

  UNSET_IS_AUTHENTICATED(state) {
    state.isAuthenticated = false;
  },

  SET_IS_ADMIN(state, payload) {
    state.isAdmin = payload;
  },

  UNSET_IS_ADMIN(state) {
    state.isAdmin = false;
  },

  SET_IS_ORG_MANAGER(state, payload) {
    state.isOrgManager = payload;
  },

  UNSET_IS_ORG_MANAGER(state) {
    state.isOrgManager = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
