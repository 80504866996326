<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import Default from "./Default";
import Admin from "./Admin";

import { markRaw, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "Root",
  setup() {
    const layout = markRaw(Admin);
    const route = useRoute();

    watch(
      () => route.meta,
      async (meta) => {
        //console.log({ meta });
        try {
          const component = await import(`@/layouts/${meta.layout}.vue`);
          layout.value = component?.default || Default;

          //meta = { layout: 'Admin' };
        } catch (e) {
          layout.value = Default;
        }
      },
      { immediate: true }
    );
    return { layout };
  },
};
</script>
