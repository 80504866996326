export default [
  {
    path: "/products",
    name: "product",
    component: () => import("@/views/products/index.vue"),
    meta: {
      layout: "Admin",
      requiresAuth: true,
      isAdmin: true,
    },
    children: [
      {
        path: "/products/activity",
        name: "product-activity",
        component: () => import("@/views/products/activity"),
      },
      {
        path: "/products/overview",
        name: "product-overview",
        component: () => import("@/views/products/overview"),
      },
      {
        path: "/products/warehouses",
        name: "product-warehouses",
        component: () => import("@/views/products/warehouses"),
      },
      {
        path: "/products/all",
        name: "product-all",
        component: () => import("@/views/products/products"),
      },
      {
        path: "/products/orders",
        name: "product-orders",
        component: () => import("@/views/products/orders"),
      },
    ],
  },
];
