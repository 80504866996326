const root = `invoice`;

export default [
  {
    path: `/${root}`,
    name: `${root}`,
    component: () => import(`@/views/${root}/index.vue`),
    meta: {
      layout: `Admin`,
      requiresAuth: true,
      isAdmin: true,
    },
    children: [
      {
        path: `/${root}/details`,
        name: `${root}-details`,
        component: () => import(`@/views/${root}/details`),
      },
      {
        path: `/${root}/customers`,
        name: `${root}-customers`,
        component: () => import(`@/views/${root}/customers`),
      },
      {
        path: `/${root}/items`,
        name: `${root}-items`,
        component: () => import(`@/views/${root}/items`),
      },
    ],
  },
];
