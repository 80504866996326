const root = `organization`;

export default [
  {
    path: `/${root}`,
    name: `${root}`,
    component: () => import(`@/views/${root}/index.vue`),
    meta: {
      layout: `Admin`,
      requiresAuth: true,
      isAdmin: true,
    },
    children: [
      {
        path: `/${root}`,
        redirect: () => {
          return `/${root}/overview`;
        },
      },
      {
        path: `/${root}/activity`,
        name: `${root}-activity`,
        component: () => import(`@/views/${root}/activity`),
      },
      {
        path: `/${root}/overview`,
        name: `${root}-overview`,
        component: () => import(`@/views/${root}/overview`),
      },
      {
        path: `/${root}/team`,
        name: `${root}-team`,
        component: () => import(`@/views/${root}/users`),
      },
      {
        path: `/${root}/roles`,
        name: `${root}-roles`,
        component: () => import(`@/views/${root}/roles`),
      },
      {
        path: `/${root}/billing`,
        name: `${root}-billing`,
        component: () => import(`@/views/${root}/billing`),
      },
      {
        path: `/${root}/reports`,
        name: `${root}-reports`,
        component: () => import(`@/views/${root}/reports`),
      },
      {
        path: `/${root}/settings`,
        name: `${root}-settings`,
        component: () => import(`@/views/${root}/settings`),
      },
      {
        path: `/${root}/invoices`,
        name: `${root}-invoices`,
        component: () => import(`@/views/${root}/invoices`),
      },
      {
        path: `/${root}/customers`,
        name: `${root}-customers`,
        component: () => import(`@/views/${root}/customers`),
      },
      {
        path: `/${root}/payments`,
        name: `${root}-payments`,
        component: () => import(`@/views/${root}/payments`),
      },
      {
        path: `/${root}/connections`,
        name: `${root}-connections`,
        component: () => import(`@/views/${root}/connections`),
      },
      {
        path: `/${root}/connections/:id/return`,
        name: `${root}-return-connections`,
        component: () => import(`@/views/${root}/connections/return`),
      },
    ],
  },
];
