import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Root from "./layouts/Root";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

// import lang from 'element-plus/lib/locale/lang/en';
// import 'dayjs/locale/en';
// import locale from 'element-plus/lib/locale';

import "./index.css";
import "./design/element.css";

import "@/design/tailwind-override.css";

// locale.use(lang);

// import VueTippy from 'vue-tippy';
import "tippy.js/dist/tippy.css";

const app = createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus)
  .component("Root", Root);

app.mount("#app");
