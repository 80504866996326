import { createRouter, createWebHistory } from "vue-router";

import common from "./modules/common";
import organization from "./modules/organization";
import admin from "./modules/admin";
import product from "./modules/product";
import account from "./modules/account";
import manufacturing from "./modules/manufacturing";
import invoice from "./modules/invoice";
import reports from "./modules/reports";

// Layouts
// import AdminLayout from '@/layouts/AdminLayout';

import store from "@/store";

export const routes = [
  ...common,
  ...organization,
  ...admin,
  ...product,
  ...account,
  ...manufacturing,
  ...invoice,
  ...reports,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store === null || store.state.auth.token === null) {
      next({
        name: "login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      if (to.matched.some((record) => record.meta.isAdmin)) {
        console.log("router", store.state);
        if (store.state.user.currentUser.SystemUserId !== null) {
          next();
        } else {
          next({ name: "userboard" }); // redirect to user
        }
      } else {
        next();
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    // if (store == null) {
    //   next();
    // } else {
    //   next({ name: 'root' });
    // }
    next();
  } else {
    next();
    // if (!hasAccess(to.name)) {
    //   router.push({
    //     name: "page-not-authorized",
    //   });
    // }
  }
});

// function hasAccess(name) {
//   const permissions = store.getters["user/getPermissions"];

//   switch (name) {
//     case "home":
//       return true;

//     case "organization":
//       return permissions.includes("Organization Setup");

//     case "users":
//       return permissions.includes("View All Users");

//     case "permissions":
//       return permissions.includes("View All Permissions");

//     case "roles":
//       return permissions.includes("View All Roles");

//     default:
//       return false;
//   }
// }

export default router;
