<template>
  <header class="bg-white shadow-sm lg:static lg:overflow-y-visible">
    <div
      class="max-full mx-auto px-4 sm:px-6 lg:divide-y lg:divide-gray-200 lg:px-8"
    >
      <div
        class="relative flex justify-between lg:gap-8 xl:grid xl:grid-cols-12"
      >
        <div
          class="flex md:absolute md:inset-y-0 md:left-0 lg:static xl:col-span-2"
        >
          <div class="flex flex-shrink-0 items-center">
            <a href="#" class="flex">
              <span v-if="getLogo() !== null">
                <img
                  class="block h-8 w-auto"
                  :src="`data:image/png;base64, ${getLogo()}`"
                  :alt="getOrgName()"
                />
              </span>

              <span
                class="ml-2 truncate font-medium"
                v-if="handleUserAccess()"
                >{{ getOrgName() }}</span
              >
              <span v-else>Super Admin</span>
            </a>
          </div>
        </div>
        <div class="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
          <div
            class="flex items-center px-6 py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0"
          ></div>
        </div>
        <div
          class="flex items-center md:absolute md:inset-y-0 md:right-0 lg:hidden"
        >
          <button
            type="button"
            class="focus:outline-none -mx-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-purple-500"
            aria-expanded="false"
          >
            <span class="sr-only">Open menu</span>
            <svg
              class="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <svg
              class="hidden h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div
          class="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4"
        >
          <router-link
            to="/account/help"
            class="hide-active-link ml-5 flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500"
          >
            <SupportIcon class="h-6 w-6" />
          </router-link>
          <router-link
            to="/account/notifications"
            class="hide-active-link ml-5 flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500"
          >
            <div class="relative inline-block">
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" />
              <span
                class="absolute top-0 right-0 block h-2.5 w-2.5 -translate-y-1/2 translate-x-1/2 transform rounded-full bg-green-400 ring-2 ring-white"
              />
            </div>
          </router-link>

          <!-- Profile dropdown -->
          <div class="z-1 relative ml-5 flex-shrink-0">
            <Menu>
              <MenuButton
                class="focus:outline-none flex max-w-xs items-center rounded-full bg-white text-sm focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50"
                id="user-menu"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <span class="sr-only">Open user menu</span>
                <img
                  v-if="
                    user.currentUser !== null &&
                    user.currentUser.Picture !== null
                  "
                  class="h-8 w-8 rounded-full"
                  :src="`data:image/png;base64, ${user.currentUser.Picture}`"
                  alt=""
                />
                <el-avatar
                  v-else
                  :size="30"
                  src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
                ></el-avatar>
                <span
                  v-if="user.currentUser !== null"
                  class="ml-3 hidden text-sm font-medium text-gray-700 lg:block"
                  ><span class="sr-only">Open user menu for </span
                  >{{ user.currentUser.FirstName }}
                  {{ user.currentUser.LastName }}</span
                >
                <!-- Heroicon name: solid/chevron-down -->
                <ChevronDownIcon
                  class="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block"
                />
              </MenuButton>
              <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-out"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
              >
                <MenuItems
                  class="focus:outline-none absolute right-0 z-20 mt-2 w-48 origin-top-right divide-y divide-gray-200 rounded-md border bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <div class="z-4 py-1" role="none">
                    <MenuItem>
                      <router-link
                        to="/account/profile"
                        class="block py-2 px-4 text-left text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        >Profile</router-link
                      >
                    </MenuItem>
                    <MenuItem>
                      <router-link
                        to="/account/security"
                        class="block py-2 px-4 text-left text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        >Security</router-link
                      >
                    </MenuItem>
                  </div>
                  <div class="py-1" role="none">
                    <MenuItem class="py-1">
                      <a
                        href="#"
                        @click.prevent="signOut"
                        class="block py-2 px-4 text-left text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        >Sign out</a
                      >
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
      <nav class="hidden lg:flex lg:space-x-8 lg:py-2" aria-label="Global">
        <router-link
          to="/admin/users"
          v-if="handleUserAccess() === 'isAdmin'"
          class="inline-flex items-center rounded-md py-2 px-3 text-sm font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
        >
          <span class="truncate text-lg"> Organizations </span>
        </router-link>
        <router-link
          to="/organization"
          v-if="handleUserAccess() === 'isOrgAdmin'"
          class="inline-flex items-center rounded-md py-2 px-3 text-sm font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
        >
          <span class="truncate text-lg"> Organization & Users </span>
        </router-link>
        <router-link
          to="/invoice/details"
          v-if="
            handleUserAccess() === 'isOrgAdmin' ||
            handleUserAccess() === 'isUser'
          "
          class="inline-flex items-center rounded-md py-2 px-3 text-sm font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
        >
          <span class="truncate text-lg"> Invoices </span>
        </router-link>
        <router-link
          to="/reports"
          v-if="
            handleUserAccess() === 'isOrgAdmin' ||
            handleUserAccess() === 'isUser'
          "
          class="inline-flex items-center rounded-md py-2 px-3 text-sm font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
        >
          <span class="truncate text-lg"> Reports </span>
        </router-link>
        <!-- <router-link
          to="/products"
          class="rounded-md py-2 px-3 inline-flex items-center text-sm font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
        >
          Products
        </router-link>
        <router-link
          to="/manufacturing"
          class="rounded-md py-2 px-3 inline-flex items-center text-sm font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
        >
          Manufacturing
        </router-link> -->
      </nav>
    </div>
  </header>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

// import API from '@/api';
import axios from "axios";

import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { BellIcon, SupportIcon, ChevronDownIcon } from "@heroicons/vue/outline";

export default defineComponent({
  name: "CommonHeader",
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    BellIcon,
    SupportIcon,
    ChevronDownIcon,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const user = computed(() => store.state.user);

    const endsession = () => {
      // const endSessionUrl =
      //   process.env.VUE_APP_API_LOCATION.replace("api/", "") +
      //   `connect/revocation`;

      // const header = {
      //   headers: {
      //     Authorization: `Bearer ${store.getters["auth/getToken"]}`,
      //   },
      // };

      // axios.post(endSessionUrl, null, header).then(() => {

      // });

      Promise.all([
        store.dispatch("auth/removeToken"),
        store.dispatch("auth/removeRefreshToken"),
        store.dispatch("auth/removeIsAuthenticated"),
        store.dispatch("user/removeUser"),
        store.dispatch("wizard/removeToken"),
        store.dispatch("wizard/removeUser"),
        store.dispatch("wizard/removeOrganization"),
      ])
        .then(() => {
          delete axios.defaults.headers.common["Authorization"];
        })
        .finally(() => {
          router.push({ name: "login" });
        });
    };

    const signOut = () => {
      endsession();
    };

    const goToNotifications = () => {
      router.push("account/notifications");
    };

    const checkLogo = () => {
      if (
        user.value.currentUser !== undefined &&
        user.value.currentUser.Organization !== null &&
        user.value.currentUser.Organization.LogoImage !== undefined
      )
        return true;

      return false;
    };

    const handleUserAccess = () => {
      if (user.value.currentUser === null) return;

      if (user.value.currentUser.Organization === null) {
        return "isAdmin";
      }

      if (
        user.value.currentUser.Organization !== null &&
        user.value.currentUser.Organization !== undefined
      ) {
        return "isOrgAdmin";
      }

      return "isUser";
    };

    const getLogo = () => {
      if (
        user.value.currentUser !== undefined &&
        user.value.currentUser !== null &&
        user.value.currentUser.Organization !== undefined &&
        user.value.currentUser.Organization !== null &&
        user.value.currentUser.Organization.LogoImage !== undefined &&
        user.value.currentUser.Organization.LogoImage !== null
      )
        return user.value.currentUser.Organization.LogoImage;

      return null;
    };

    const getOrgName = () => {
      if (
        user.value.currentUser !== undefined &&
        user.value.currentUser.Organization !== undefined &&
        user.value.currentUser.Organization !== null &&
        user.value.currentUser.Organization.OrganizationName !== undefined
      )
        return user.value.currentUser.Organization.OrganizationName;

      return null;
    };

    const handleAccess = () => {
      if (
        handleUserAccess() === "isOrgAdmin" ||
        handleUserAccess() === "isUser"
      )
        return true;

      return false;
    };

    return {
      store,
      user,
      getLogo,
      getOrgName,
      signOut,
      checkLogo,
      goToNotifications,
      handleUserAccess,
      handleAccess,
    };
  },
});
</script>

<style scoped>
.dropdown--container {
  width: 300px;
  padding: 16px;
}

.text-lg {
  font-size: 0.95rem;
}
</style>
