const root = `admin`;

export default [
  {
    path: `/${root}`,
    name: `${root}`,
    component: () => import(`@/views/${root}/index.vue`),
    meta: {
      layout: `Admin`,
      requiresAuth: true,
      isAdmin: true,
    },
    children: [
      {
        path: `/${root}/users`,
        name: `${root}-users`,
        component: () => import(`@/views/${root}/users`),
      },
      {
        path: `/${root}/roles`,
        name: `${root}-roles`,
        component: () => import(`@/views/${root}/roles`),
      },
      {
        path: `/${root}/organisations`,
        name: `${root}-organisations`,
        component: () => import(`@/views/${root}/organisations`),
      },
      {
        path: `/${root}/subscriptions`,
        name: `${root}-subscriptions`,
        component: () => import(`@/views/${root}/subscriptions`),
      },
      {
        path: `/${root}/management`,
        name: `${root}-management`,
        component: () => import(`@/views/${root}/management`),
      },
      {
        path: `/${root}/reports`,
        name: `${root}-reports`,
        component: () => import(`@/views/${root}/reports`),
      },
      {
        path: `/${root}/reports`,
        name: `${root}-reports`,
        component: () => import(`@/views/${root}/reports`),
      },
    ],
  },
];
