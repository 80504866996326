export default [
  {
    path: "/manufacturing",
    name: "manufacturing",
    component: () => import("@/views/manufacturing/index.vue"),
    meta: {
      layout: "Admin",
      requiresAuth: true,
      isAdmin: true,
    },
    children: [
      {
        path: "/manufacturing/assets",
        name: "manufacturing-assets",
        component: () => import("@/views/manufacturing/assets"),
      },
      {
        path: "/manufacturing/materials",
        name: "manufacturing-materials",
        component: () => import("@/views/manufacturing/materials"),
      },
      {
        path: "/manufacturing/production",
        name: "manufacturing-production",
        component: () => import("@/views/manufacturing/production"),
      },
      {
        path: "/manufacturing/quality-assurance",
        name: "manufacturing-qualityAssurance",
        component: () => import("@/views/manufacturing/qualityAssurance"),
      },
      {
        path: "/manufacturing/supply-chain",
        name: "manufacturing-supplyChain",
        component: () => import("@/views/manufacturing/supplyChain"),
      },
    ],
  },
];
