export default [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/common/Home.vue"),
    meta: {
      guest: true,
      layout: "Default",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/common/Login"),
    meta: {
      guest: true,
      layout: "Default",
    },
  },
  {
    path: "/registration",
    name: "registration",
    component: () => import("@/views/common/Registration"),
    meta: {
      guest: true,
      layout: "Default",
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/common/ForgotPassword.vue"),
    meta: {
      guest: true,
      layout: "Default",
    },
  },
  {
    path: "/changepassword/:id",
    name: "changepassword",
    component: () => import("@/views/common/ChangePassword.vue"),
    meta: {
      guest: true,
      layout: "Default",
    },
  },
  {
    path: "/onboarding/:orgId",
    name: "onboarding", //invitation
    component: () => import("@/views/common/Onboarding.vue"),
    meta: {
      guest: true,
      layout: "Default",
    },
  },
  // {
  //   path: '/settings',
  //   name: 'settings',
  //   component: () => import('@/views/common/Settings.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: '/profile',
  //   name: 'profile',
  //   component: () => import('@/views/common/Profile.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/help",
    name: "help",
    component: () => import("@/views/common/help"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("@/views/common/privacy"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("@/views/common/Notifications"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "pageNotFound",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/common/components/404.vue"),
    meta: {
      requiresAuth: false,
    },
  },
];
