import { createStore, createLogger } from "vuex";
import createPersistedState from "vuex-persistedstate";
// Modules
import auth from "./modules/auth";
import user from "./modules/user";
import wizard from "./modules/wizard";

const debug = process.env.NODE_ENV !== "production";

const store = createStore({
  modules: {
    auth,
    user,
    wizard,
  },
  strict: debug,
  plugins: [createPersistedState(), createLogger()],
});

export default store;
