<template>
  <div class="min-h-screen bg-gray-100">
    <main-navbar />
    <div class="py-10">
      <slot />
    </div>
  </div>
</template>

<script>
import MainNavbar from "@/common/components/navigation/navbar/_main.vue";

export default {
  name: "AdminLayout",
  components: {
    MainNavbar,
  },
};
</script>
