<template>
  <template v-if="isAuth">
    <Root>
      <router-view />
    </Root>
  </template>
  <template v-else>
    <Default>
      <router-view />
    </Default>
  </template>
</template>

<script>
import Root from "@/layouts/Root";
import Default from "@/layouts/Default";

import { computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "App",
  components: { Root, Default },
  setup() {
    const store = useStore();
    const route = useRoute();

    let isAuth = computed(() =>
      store.state.auth.token !== null ? true : false
    );

    watch(
      () => route,
      async (meta) => {
        if (meta.guest) isAuth = false;
      },
      { immediate: true }
    );

    return {
      isAuth,
    };
  },
};
</script>

<style>
#app {
  /* font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'; */
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 16px;
}
</style>
